import React from 'react';
import { useRouteMatch, NavLink as RouterNavLink } from 'react-router-dom';
import { Button, ButtonGroup } from '@chakra-ui/react';
import i18n from 'i18next';

import { FILTER_MODE_NEW, routerCategories, routerFilters } from '../../utils';

function Filters(): React.ReactElement {
  const {
    params: { category, mode },
  }: any = useRouteMatch({
    path: [
      `/:lang/:category(${routerCategories})/:mode(${routerFilters})`,
      `/:lang/:category(${routerCategories})`,
      `/:lang/:mode(${routerFilters})`,
      `/:lang/`,
      `/:lang`,
    ],
    sensitive: true,
    strict: true,
  });

  return (
    <ButtonGroup
      color="var(--colors-heading)"
      sx={{
        width: '100%',
        padding: '0.3rem',
        borderRadius: '5rem',
        background: 'var(--colors-filterBackgroundGroup)',
        '@media (min-width: 992px)': {
          background: 'transparent',
          width: 'initial',
          padding: 0,
        },
      }}
    >
      <Button
        to={category ? `/${i18n.language}/${category}` : `/${i18n.language}/`}
        as={RouterNavLink}
        size="sm"
        variant="ghost"
        colorScheme="blue"
        borderRadius="5rem"
        color="var(--colors-filterColor)"
        _focus={{
          background: 'var(--colors-filterBackgroundSmall)',
          '@media (min-width: 992px)': {
            background: 'var(--colors-filterBackgroundLarge)',
          },
        }}
        _active={{
          cursor: 'default',
          background: 'var(--colors-filterBackgroundSmall)',
          '@media (min-width: 992px)': {
            background: 'var(--colors-filterBackgroundLarge)',
          },
        }}
        _hover={{
          background: 'var(--colors-filterBackgroundSmall)',
          '@media (min-width: 992px)': {
            background: 'var(--colors-filterBackgroundLarge)',
          },
        }}
        isActive={!mode}
        sx={{
          width: '50%',
          '@media (min-width: 992px)': {
            width: 'initial',
          },
        }}
      >
        {i18n.t('main.filters.top')}
      </Button>
      <Button
        to={category ? `/${i18n.language}/${category}/${FILTER_MODE_NEW}` : `/${i18n.language}/${FILTER_MODE_NEW}`}
        as={RouterNavLink}
        size="sm"
        variant="ghost"
        colorScheme="blue"
        borderRadius="5rem"
        color="var(--colors-filterColor)"
        _focus={{
          background: 'var(--colors-filterBackgroundSmall)',
          '@media (min-width: 992px)': {
            background: 'var(--colors-filterBackgroundLarge)',
          },
        }}
        _active={{
          cursor: 'default',
          background: 'var(--colors-filterBackgroundSmall)',
          '@media (min-width: 992px)': {
            background: 'var(--colors-filterBackgroundLarge)',
          },
        }}
        _hover={{
          background: 'var(--colors-filterBackgroundSmall)',
          '@media (min-width: 992px)': {
            background: 'var(--colors-filterBackgroundLarge)',
          },
        }}
        isActive={mode === FILTER_MODE_NEW}
        sx={{
          width: '50%',
          '@media (min-width: 992px)': {
            width: 'initial',
          },
        }}
      >
        {i18n.t('main.filters.new')}
      </Button>
    </ButtonGroup>
  );
}

export default Filters;
