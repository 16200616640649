import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Flex, Text, Image, LinkOverlay, Heading, VStack } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroller';
import { FiClock } from 'react-icons/fi';
import i18n from 'i18next';

import { Position } from '../../components/Widget/types';
import Widget, { ListLink } from '../../components/Widget';
import Filters from '../../components/Filters';
import FeedUp from '../../components/FeedUp';
import Sticky from '../../components/Sticky';
import Share from '../../components/Share';
import Card from '../../components/Card';
import Date from '../../components/Date';
import { canUseDom } from '../../utils';
import { useHome } from './useHome';
import Top from './Top';

function Home(): React.ReactElement {
  const location = useLocation();
  const data = useHome();

  function renderStories() {
    if (data.error) return <>{i18n.t('error')}</>;
    if (data.loading && !data.stories) return <>{i18n.t('loading')}</>;

    return data?.stories?.map(({ hash, title, snippet, image, url, sharer, createdAt }: any) => {
      return (
        <Card as="article" key={hash} onMouseOver={() => data.prefetch(hash)} onTouchStart={() => data.prefetch(hash)}>
          <LinkOverlay
            as={RouterLink}
            to={url}
            color="var(--colors-cardTitleColor)"
            zIndex={99999999}
            _before={{
              zIndex: 1,
            }}
            _focus={{
              boxShadow: 'none',
            }}
            _hover={{
              textDecoration: 'none',
            }}
          >
            <Heading as="h2" fontSize="1.5rem" p="0.75rem 0.75rem 0 0.75rem">
              {title}
            </Heading>
          </LinkOverlay>
          {snippet && (
            <Text fontSize="1rem" p="0.5rem 0.75rem 0 0.75rem">
              {snippet}
            </Text>
          )}
          {image && (
            <Flex as="figure" p="0.75rem 0 0" overflow="hidden" opacity={data.figureOpacity}>
              <Image src={image} alt={title} borderRadius="0.375rem" objectFit="cover" width="100%" />
            </Flex>
          )}
          <Flex justifyContent="space-between" alignItems="center" fontSize="0.9rem" p="3">
            <Flex alignItems="center" lineHeight="1" textTransform="capitalize">
              <FiClock style={{ marginRight: '0.25rem' }} />
              <Date createdAt={createdAt} />
            </Flex>
            <Flex>
              <Share data={sharer} />
            </Flex>
          </Flex>
        </Card>
      );
    });
  }

  return (
    <>
      <Sticky as="nav" w="220px" top="0">
        <Widget
          position={Position.Left}
          title={i18n.t('main.widgets.navigate.title')}
          content={
            <>
              <ListLink position={Position.Left} items={data.filters} />
              <ListLink position={Position.Left} items={data.categories} />
            </>
          }
        />
      </Sticky>

      <Flex
        w="100%"
        mb="1rem"
        direction="column"
        sx={{
          maxWidth: '992px',
          '@media (min-width: 992px)': {
            maxWidth: '700px',
          },
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="1rem"
          w="100%"
          sx={{
            gap: '0.75rem',
            marginBottom: '1rem',
            flexDirection: 'column',
            '@media (min-width: 992px)': {
              flexDirection: 'row',
            },
          }}
        >
          <Flex
            alignItems="center"
            color="var(--colors-heading)"
            sx={{
              display: 'none',
              '@media (min-width: 992px)': {
                display: 'flex',
              },
            }}
          >
            <Helmet>
              <title>{data.meta.title}</title>
              <meta name="description" content={data.meta.description} />
              <meta name="keywords" content={data.meta.keywords} />
              <link rel="canonical" href={process.env.REACT_APP_CLIENT_HOST + location?.pathname} />
            </Helmet>
            <Heading
              as="h1"
              userSelect="none"
              borderBottom="1px dashed"
              fontSize={['1rem', '1.10rem', '1.15rem', '1.25rem']}
              sx={{
                marginLeft: 0,
                '@media (min-width: 992px)': {
                  marginLeft: '0.875rem',
                },
              }}
            >
              {i18n.t('main.feed')}
            </Heading>
          </Flex>
          <Filters />
        </Flex>
        <InfiniteScroll hasMore={!!data?.cursor?.afterCursor} loadMore={data.getMore}>
          <VStack spacing={4}>
            {canUseDom && data.mode === 'new' && !data.error && !data.loading && <FeedUp refetch={data.refetch} />}
            {renderStories()}
          </VStack>
        </InfiniteScroll>
      </Flex>

      <Sticky as="aside" w="220px" h="100vh" top="0">
        <Widget position={Position.Right} title={i18n.t('main.widgets.popular.title')} content={<Top />} />
      </Sticky>
    </>
  );
}

export default Home;
