import React, { useCallback, useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { FiArrowUp } from 'react-icons/fi';
import { Button } from '@chakra-ui/react';
import i18n from 'i18next';

import SUBSCRIBE_STORIES from '../../graphql/subscriptions/subscribeStories';
import { hexToRgba } from '../../utils';
import { TFeedUp } from './types';

function FeedUp({ refetch }: TFeedUp): React.ReactElement | null {
  const [counter, setCounter] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const { data } = useSubscription(SUBSCRIBE_STORIES, {
    variables: {
      language: i18n.language,
    },
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    data && setCounter((prevState) => prevState + 1);
  }, [data]);

  const handleScroll = useCallback(() => {
    setOffset(window.scrollY);
  }, []);

  const handleRefetch = useCallback(() => {
    window.document.body.scrollTop = 0; // For Safari
    window.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setCounter(0);
    refetch();
  }, []);

  if (!counter) return null;

  return (
    <Button
      size="sm"
      fontSize="sm"
      variant="ghost"
      leftIcon={<FiArrowUp size="1rem" />}
      boxShadow={`0 0 1rem 0 ${hexToRgba('#000000', 0.1)}`}
      backgroundColor="var(--colors-feedUpBackground)"
      color="var(--colors-feedUpColor)"
      transition="all .1s ease-in-out"
      borderRadius="5rem"
      position="sticky"
      top={5}
      zIndex={2}
      _focus={{
        boxShadow: `0 0 1rem 0 ${hexToRgba('#000000', 0.15)}`,
      }}
      _active={{
        boxShadow: `0 0 1rem 0 ${hexToRgba('#000000', 0.15)}`,
      }}
      _hover={{
        boxShadow: `0 0 1rem 0 ${hexToRgba('#000000', 0.15)}`,
      }}
      sx={{
        width: offset < 100 ? '100%' : '75%',
        '@media (min-width: 768px)': {
          width: offset < 100 ? '100%' : '60%',
        },
        '@media (min-width: 992px)': {
          width: offset < 100 ? '100%' : '50%',
        },
      }}
      onClick={handleRefetch}
    >
      {`${i18n.t('main.feedUp.show')} ${counter} ${i18n.t('main.feedUp.more')}`}
    </Button>
  );
}

export default FeedUp;
