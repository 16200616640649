import i18n from 'i18next';
import { useQuery } from '@apollo/client';

import GET_STORIES_TOP from '../../../graphql/queries/getStoriesTop';

export default function useTop() {
  const { loading, error, data } = useQuery(GET_STORIES_TOP, {
    fetchPolicy: 'cache-first',
    variables: { language: i18n.language },
  });

  const transform = (data: any) => {
    return data?.storiesTop?.map((story: any) => ({
      exact: true,
      name: story?.createdNews?.info?.title,
      to: `/${i18n.language}/stories/${story?.createdNews?.info?.slug}-${story?.hash}`,
    }));
  };

  return {
    loading,
    error,
    data: transform(data),
  };
}
