import { gql } from 'graphql.macro';

const GET_STORIES_TOP = gql`
  query storiesTop($language: String!) {
    storiesTop(language: $language) {
      hash
      createdNews {
        id
        hash
        info {
          slug
          title
          snippet
        }
        source {
          name
        }
      }
    }
  }
`;

export default GET_STORIES_TOP;
