import { useRouteMatch } from 'react-router-dom';
import { useColorModeValue } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import i18n from 'i18next';

import GET_STORIES from '../../graphql/queries/getStories';
import GET_STORY from '../../graphql/queries/getStory';
import { routerCategories, routerFilters, getImg, getTitle, getCategories, getFilters } from '../../utils';

export function useHome() {
  const figureOpacity = useColorModeValue('1', '0.6');
  const {
    params: { category, mode },
  }: any = useRouteMatch({
    path: [
      `/:lang/:category(${routerCategories})/:mode(${routerFilters})`,
      `/:lang/:category(${routerCategories})`,
      `/:lang/:mode(${routerFilters})`,
      `/:lang/`,
      `/:lang`,
    ],
    sensitive: true,
    strict: true,
  });

  const { loading, error, data, fetchMore, refetch, client } = useQuery(GET_STORIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      language: i18n.language,
      category,
      mode,
    },
  });

  const transformStories = (data: any): [] => {
    return data?.stories?.data?.map((story: any) => {
      const hash = story?.hash;
      const images = story?.images;
      const createdNews = story?.createdNews;
      const title = createdNews?.info?.title;
      const snippet = createdNews?.info?.snippet;
      const url = `/${i18n.language}/stories/${createdNews?.info?.slug}-${hash}`;
      const image = getImg(
        images?.[0]?.name,
        { hash: images?.[0]?.hash, created_at: images?.[0]?.created_at },
        'original'
      );

      return {
        hash,
        url,
        image,
        title,
        snippet,
        createdAt: story?.created_at,
        sharer: {
          title,
          image,
          url: process.env.REACT_APP_CLIENT_HOST + url,
          subject: `${title} | news.24.md`,
          body: '',
        },
      };
    });
  };

  const handleMeta = (category: string, mode: string) => {
    const metaKey = [mode === 'new' ? 'new' : 'top', category].filter(Boolean).join('/');

    return {
      title: getTitle(i18n.t(`meta.${metaKey}.title`) + ' | news.24.md'),
      description: i18n.t('meta.top.description'),
      keywords: i18n.t('meta.top.keywords'),
    };
  };

  const handleMore = () => {
    return (
      data?.stories?.cursor?.afterCursor &&
      fetchMore({
        variables: {
          afterCursor: data?.stories?.cursor?.afterCursor,
          language: i18n.language,
          category,
          mode,
        },
        updateQuery: (previousResult: any, { fetchMoreResult }) => ({
          ...previousResult,
          stories: {
            ...previousResult?.stories,
            cursor: fetchMoreResult?.stories?.cursor,
            data: [...previousResult?.stories?.data, ...fetchMoreResult?.stories?.data],
          },
        }),
      })
    );
  };

  const handlePrefetch = (hash: string) => {
    client.query({
      query: GET_STORY,
      variables: {
        withCounter: false,
        language: i18n.language,
        hash,
      },
    });
  };

  const handleRefetch = () => {
    return refetch({
      language: i18n.language,
    });
  };

  return {
    mode,
    error,
    loading,
    figureOpacity,
    cursor: data?.stories?.cursor,
    stories: transformStories(data),
    meta: handleMeta(category, mode),
    categories: getCategories(mode),
    filters: getFilters(category),
    prefetch: handlePrefetch,
    refetch: handleRefetch,
    getMore: handleMore,
  };
}
