import React from 'react';
import i18n from 'i18next';

import { Position } from '../../../components/Widget/types';
import { ListLink } from '../../../components/Widget';
import useTop from './useTop';

function Top(): React.ReactElement {
  const top = useTop();

  if (top.loading) return <>{i18n.t('loading')}</>;
  if (top.error) return <>{i18n.t('error')}</>;

  return <ListLink position={Position.Right} items={top.data} />;
}

export default Top;
