import { gql } from 'graphql.macro';

const GET_STORIES = gql`
  query Stories($language: String!, $category: String, $mode: String, $afterCursor: String) {
    stories(language: $language, category: $category, mode: $mode, afterCursor: $afterCursor) {
      cursor {
        beforeCursor
        afterCursor
      }
      data {
        hash
        createdNews {
          hash
          info {
            slug
            title
            snippet
          }
        }
        images {
          hash
          name
          created_at
        }
        created_at
      }
    }
  }
`;

export default GET_STORIES;
