import { gql } from 'graphql.macro';

const SUBSCRIBE_STORIES = gql`
  subscription {
    storyAdded {
      hash
      createdNews {
        id
        hash
        views
        info {
          slug
          title
          snippet
        }
        source {
          name
        }
        created_at
      }
      images {
        hash
        name
        hex_dominant_color
        hex_background_color
        hex_title_color
        hex_body_color
        created_at
      }
      created_at
    }
  }
`;

export default SUBSCRIBE_STORIES;
